@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  font-family: ui-sans-serif,system-ui,Helvetica,Arial,sans-serif;
  letter-spacing: 0.8px;  

  width: 100%;
  height: 100vh;

  color: rgb(61 62 67);
}

#root {
  width: 100%;
  height:100%;
}

.Video:hover {
  cursor: crosshair;
}